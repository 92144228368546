import {getOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import {buildCatDisplayMap} from 'rev.sdk.js/Utils/buildCatDisplayMap';
import Config from '../../data.json';

export async function onCtxRendered() {
  let configs = null;

  if (Config.siteCacheUrl) {
    try {
      configs = await ApiUtil.req(Config.siteCacheUrl, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });
    } catch (ex1) {
      console.warn('[AppContext] siteCacheUrl is set but no data');
    }
  }

  if (!configs) {
    try {
      configs = await JStorage.fetchDocuments('site', {}, null, null);
    } catch (ex2) {
      console.warn('[AppContext] fail to fetch site collection');
      configs = [];
    }
  }

  for (const cfg of configs) {
    if (cfg.name === 'product_category') {
      cfg.categories.meta_data = {
        ...cfg.meta_data,
      };

      getOutlet('categories').update(cfg.categories || []);
      getOutlet('categoryDisplayMap').update(
        buildCatDisplayMap(cfg.categories || []),
      );
      console.log('DBGGG', cfg.categories);
      console.log('DBGGG', buildCatDisplayMap(cfg.categories || []));
    } else if (cfg.name === 'video_category') {
      cfg.categories.meta_data = {
        ...cfg.meta_data,
      };

      getOutlet('videoCategories').update(cfg.categories || []);
      getOutlet('videoCategoryDisplayMap').update(
        buildCatDisplayMap(cfg.categories || []),
      );
    } else if (cfg.name === 'article_category') {
      cfg.categories.meta_data = {
        ...cfg.meta_data,
      };

      getOutlet('articleCategories').update(cfg.categories || []);
      getOutlet('articleCategoryDisplayMap').update(
        buildCatDisplayMap(cfg.categories || []),
      );
    } else if (cfg.name === 'landing') {
      getOutlet('landing').update(cfg || []);
    } else if (cfg.name === 'config') {
      getOutlet('config').update(cfg || []);
    } else if (cfg.name === 'about') {
      getOutlet('about').update(cfg || []);
    }
  }
}
