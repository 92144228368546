import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {getOutlet} from 'reconnect.js';

export async function fetchReservations() {
  const user = getOutlet('user').getValue();
  const allItems = await JStorage.fetchDocuments(
    'reservation',
    {
      dealer: user.sub,
    },
    null,
    null,
  );
  getOutlet('reservation').update(allItems);
}

export async function createNewReservation(name) {
  const user = getOutlet('user').getValue();
  await JStorage.createDocument('reservation', {
    name,
    dealer: user.sub,
    items: [],
  });
  await fetchReservations();
}

export async function renameReservation(reservation, name) {
  const user = getOutlet('user').getValue();
  await JStorage.updateDocument(
    'reservation',
    {
      id: reservation.id,
    },
    {
      name,
    },
  );
  await fetchReservations();
}

export async function addProductToReservation(reservation, product, quantity) {
  // because ProductDetail might in a new page, so we have to refetch to ensure we get latest data
  reservation = await JStorage.fetchOneDocument('reservation', {
    id: reservation.id,
  });

  product = {...product};
  // NOTICE: remove the images field because they're base64 string now
  delete product.images;

  const prevItemIdx = reservation.items.findIndex(
    (r) => r.model === product.model,
  );

  if (prevItemIdx > -1) {
    const nextItems = [...reservation.items];
    nextItems[prevItemIdx].quantity = quantity;
    await JStorage.updateDocument(
      'reservation',
      {
        id: reservation.id,
      },
      {
        items: nextItems,
      },
    );
  } else {
    await JStorage.updateDocument(
      'reservation',
      {
        id: reservation.id,
      },
      {
        items: [...reservation.items, {...product, quantity}],
      },
    );
  }

  await fetchReservations();
}

export async function removeProductFromReservation(reservation, productIdx) {
  const nextItems = [...reservation.items];
  nextItems.splice(productIdx, 1);
  await JStorage.updateDocument(
    'reservation',
    {
      id: reservation.id,
    },
    {
      items: nextItems,
    },
  );
  await fetchReservations();
}

export async function deleteReservation(reservation) {
  const user = getOutlet('user').getValue();
  await JStorage.deleteDocument('reservation', {
    id: reservation.id,
    dealer: user.sub,
  });
  await fetchReservations();
}
