import Jimp from 'jimp';
import {promisify} from 'util';

const JimpRead = promisify(Jimp.read.bind(Jimp));

function base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 1024;
  var byteCharacters = window.atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, {type: contentType});
}

async function generateThumbnails(objUrl) {
  function makeAsyncAble(jimpObj) {
    return {
      jimpObj,
      resize: promisify(jimpObj.resize.bind(jimpObj)),
    };
  }

  const _JimpRead = (path) => {
    return new Promise((resolve, reject) => {
      Jimp.read(path, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(makeAsyncAble(result));
        }
      });
    });
  };

  let header, base64WithMime, imgBlob, outImgUrl, outImgUrlSmall;
  const rawImg = await _JimpRead(objUrl);
  const imgW = rawImg.jimpObj.getWidth();
  const imgH = rawImg.jimpObj.getHeight();

  let ratio = imgW / imgH;
  let w = 1000;
  let h = 1000;

  if (imgW > w) {
    h = w / ratio;
  } else {
    w = imgW;
    h = imgH;
  }

  await rawImg.resize(w, h);

  header = 'data:image/png;base64,';
  base64WithMime = await rawImg.jimpObj.getBase64Async('image/png');
  imgBlob = base64toBlob(base64WithMime.slice(header.length), 'image/png');
  outImgUrl = URL.createObjectURL(imgBlob);

  w = 300;
  h = 300;

  if (imgW > w) {
    h = w / ratio;
  } else {
    w = imgW;
    h = imgH;
  }

  await rawImg.resize(w, h);
  header = 'data:image/png;base64,';
  base64WithMime = await rawImg.jimpObj.getBase64Async('image/png');
  imgBlob = base64toBlob(base64WithMime.slice(header.length), 'image/png');
  outImgUrlSmall = URL.createObjectURL(imgBlob);

  return {
    img: outImgUrl,
    imgSmall: outImgUrlSmall,
  };
}

async function processImage(objUrl, onDealerImageCreated) {
  const config = {
    workspaceDir: 'upload-workspace',
    outputImgDir: 'upload-to-s3',
    dealers: ['gatsby', 'nb', 'revtel'],
    iconSize: 100,
    iconExt: '.png',
    iconDir: './static/dealers',
  };

  function makeAsyncAble(jimpObj) {
    return {
      scaleToFit: promisify(jimpObj.scaleToFit.bind(jimpObj)),
      write: promisify(jimpObj.write.bind(jimpObj)),
      blit: promisify(jimpObj.blit.bind(jimpObj)),
      opacity: promisify(jimpObj.opacity.bind(jimpObj)),
    };
  }

  for (const dealer of config.dealers) {
    const productImg = await JimpRead(objUrl);
    const dealerImg = await JimpRead(`/dealers/${dealer}${config.iconExt}`);
    const productImgAsync = makeAsyncAble(productImg);
    const dealerImgAsync = makeAsyncAble(dealerImg);
    await dealerImgAsync.scaleToFit(config.iconSize, config.iconSize);
    await dealerImgAsync.opacity(0.5);
    await productImgAsync.blit(dealerImg, 80, 80);
    const header = 'data:image/png;base64,';
    const base64WithMime = await productImg.getBase64Async('image/png');
    const imgBlob = base64toBlob(
      base64WithMime.slice(header.length),
      'image/png',
    );
    const imgUrl = URL.createObjectURL(imgBlob);
    onDealerImageCreated({
      dealer,
      img: imgUrl,
    });
  }
}

export {processImage, generateThumbnails};
