import React from 'react';
import styled from 'styled-components';
// import {navigate, Link} from 'gatsby';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import {Avatar, Badge, Button} from 'antd';
import useBreakpoint from '../../Hooks/useBreakPoint';
import Link from '../NavLink';
import * as AppActions from '../../AppActions';
import {ShoppingCart} from '@styled-icons/entypo';
import {ShoppingBag} from '@styled-icons/boxicons-regular';
import {PersonCircle} from '@styled-icons/ionicons-outline';

const navItems = [
  {children: '最新消息', url: '/news'},
  {children: '產品型錄', url: '/products'},
  {children: '影片資訊', url: '/videos'},
  {children: '個人資訊', url: '/profile'},
  {children: '預購清單', url: '/reservations'},
];

function _isInSubPath({pathname = '', navItemUrl}) {
  return pathname.indexOf(navItemUrl) >= 0;
}

function SiteNavBar(props) {
  const {location} = props;
  const [user] = useOutlet('user');
  const [cart] = useOutlet('cart');
  const [dimension] = useOutlet('dimension');
  const showLoginModal = useOutletSetter('login-modal');
  const {passBreakpoint} = useBreakpoint(100);
  const [mobileNavVisible, setMobileNavVisible] = React.useState(false);
  const mobile = dimension.rwd !== 'desktop';
  const pathname = location?.pathname;

  console.log('location', pathname);

  React.useEffect(() => {
    setMobileNavVisible(false);
  }, [pathname]);

  return (
    <>
      <NavBar hasBorder={passBreakpoint} bgColor={'white'} style={{height: 64}}>
        <div className="constrain">
          <Logo style={{cursor: 'pointer'}}>
            <Link to="/news">
              <img
                src="/images/Logo.png"
                alt="Logo"
                style={{
                  height: dimension.rwd === 'desktop' ? 30 : 20,
                  objectFit: 'contain',
                }}
              />
            </Link>
          </Logo>
          {mobile && (
            <>
              <div style={{flex: 1}} />
              <Button onClick={() => setMobileNavVisible(true)}>選單</Button>
            </>
          )}

          {!mobile && (
            <>
              {navItems.map(({children, url}, idx) => {
                let selected = _isInSubPath({
                  pathname,
                  navItemUrl: url,
                });
                return (
                  <Link
                    to={url}
                    className="nav-link"
                    key={idx}
                    extraStyle={{
                      color: '#000',
                      letterSpacing: 1,
                      fontWeight: selected ? '600' : '400',
                      padding: '19px 10px',
                      margin: '0px 20px',
                    }}>
                    {children}
                  </Link>
                );
              })}

              <div style={{flex: 1}}></div>

              {/* <Button
                type="link"
                onClick={() => {
                  AppActions.navigate('/checkout');
                }}>
                <Badge count={cart.items.length}>
                  <ShoppingBag size={24} color={'#0eb407'} />
                </Badge>
              </Button> */}

              {user ? (
                <div></div>
              ) : (
                // <Button
                //   type="link"
                //   onClick={() => {
                //     AppActions.navigate('/profile');
                //   }}>
                //   <Badge count={cart.items.length}>
                //     <PersonCircle size={26} color={'#000'} />
                //   </Badge>
                // </Button>
                <Button onClick={() => showLoginModal(true)}>登入</Button>
              )}
            </>
          )}
        </div>
      </NavBar>

      <MobileNav
        {...props}
        visible={mobile && mobileNavVisible}
        setVisible={setMobileNavVisible}
      />
    </>
  );
}

const NavBar = styled.div`
  position: fixed;
  background-color: #ffffff;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: var(--topNavBarHeight);
  padding: 16px 40px;
  display: flex;
  align-items: center;
  z-index: 1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.033);
  /* box-shadow: ${(props) =>
    props.hasBorder
      ? '0 10px 20px rgba(0, 0, 0, 0.019), 0 6px 6px rgba(0, 0, 0, 0.033)'
      : 'none'}; */
  transition: 200ms;

  & .constrain {
    margin: 0 auto;
    max-width: var(--contentMaxWith);
    display: flex;
    flex: 1;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 18px;
`;

function MobileNav(props) {
  const showLoginModal = useOutletSetter('login-modal');
  const {visible, setVisible} = props;
  return (
    <>
      <Backdrop visible={visible} onClick={() => setVisible(false)} />
      <MobileContent visible={visible}>
        <Logo
          style={{cursor: 'pointer', alignSelf: 'center', marginBottom: 20}}>
          <Link to="/">
            <img src="/images/Logo.png" alt="site logo" />
          </Link>
        </Logo>

        {navItems.map(({children, url}, idx) => {
          let selected = _isInSubPath({
            pathname: props.location?.pathname,
            navItemUrl: url,
          });
          return (
            <Link
              to={url}
              className="nav-link"
              key={idx}
              extraStyle={{
                color: '#000',
                letterSpacing: 1,
                fontWeight: selected ? '600' : '400',
                padding: '19px 10px',
                margin: '0px 20px',
              }}>
              {children}
            </Link>
          );
        })}
      </MobileContent>
    </>
  );
}

const Backdrop = styled.div`
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  ${(props) =>
    !props.visible &&
    `
    pointer-events: none;
  `}
  transition: 300ms;
`;

const MobileContent = styled.div`
  z-index: 101;
  position: fixed;
  left: 0;
  top: 0;
  width: 240px;
  height: 100vh;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: ${(props) =>
    props.visible ? 'translateX(0px)' : 'translateX(-300px)'};
  ${(props) =>
    !props.visible &&
    `
    pointer-events: none;
  `}
  transition: 300ms;

  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export default SiteNavBar;
