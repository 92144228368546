import React from 'react';
import styled from 'styled-components';
import {navigate, Link} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import {Facebook} from '@styled-icons/boxicons-logos';

let LINK_ITEMS = [
  {
    title: '關於Pinbar',
    to: '',
    items: [
      {
        title: '品牌介紹',
        to: '',
      },
      {
        title: '服務流程',
        to: '',
      },
      {
        title: '客製化諮詢',
        to: '',
      },
      {
        title: '聯絡Pinbar',
        to: '',
      },
    ],
  },
  {
    title: '產品服務',
    to: '',
    items: [
      {
        title: '客製化拼圖',
        to: '',
      },
      {
        title: '客製化樂高',
        to: '',
      },
      {
        title: '設計師作品',
        to: '',
      },
    ],
  },
  {
    title: '問答FAQ',
    to: '',
    items: [
      {
        title: '購物指南',
        to: '',
      },
      {
        title: '客製化須知',
        to: '',
      },
      {
        title: '常見問題',
        to: '',
      },
      {
        title: '條款聲明',
        to: '',
      },
    ],
  },
  {
    title: '部落格',
    to: '',
    items: [
      {
        title: '精選文章',
        to: '',
      },
      {
        title: '作品分享',
        to: '',
      },
      {
        title: '拼圖專欄',
        to: '',
      },
    ],
  },
];

function SiteFooter(props) {
  const [dimension] = useOutlet('dimension');

  return (
    <FlexItemSection isMobile={!(dimension.rwd === 'desktop')}>
      <div
        className="content-section"
        style={{
          padding: 30,
          flexDirection: dimension.rwd === 'desktop' ? 'row' : 'column',
        }}>
        <div className="constrain">
          <img
            src="/images/Logo3.png"
            alt="Logo"
            style={{
              width: 200,
              height: 80,
              objectFit: 'contain',
              margin: '0 auto',
            }}
          />
        </div>
      </div>
      <div className="bottom-section">
        <div className="constrain">
          <div>© 2021 New Balance.</div>
        </div>
      </div>
    </FlexItemSection>
  );
}

const FlexItemSection = styled.section`
  box-shadow: 5px -3px 26px 26px rgba(0, 0, 0, 0.033);
  & > .content {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  width: 100vw;

  & .constrain {
    margin: 0 auto;
    max-width: var(--contentMaxWith);
    display: flex;
    flex: 1;
    ${(props) => console.log('props', props)}
    flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
    /* align-items: center; */
  }

  & > .bottom-section {
    background-color: #f6f6f6;
    padding: 25px 40px;
    font-size: 13px;
  }

  & > .content-section {
    display: flex;

    & .info {
      flex-basis: 100px;
      & .info-text {
        color: #707070;
        margin-top: 8px;
        :first-child {
          margin-top: 0px;
        }
      }
      & img {
        cursor: pointer;
      }
    }

    & .link-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    & .link-item {
      padding: 5px 0px;
      cursor: pointer;
      font-size: 16px;
      transition: all 0.2s ease-out;
      white-space: break-spaces;
      text-align: ${(props) => (props.isMobile ? 'center' : 'left')};
      color: #707070;

      :hover {
        color: #000;
      }
    }

    & .social-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border: 1px solid #eee;
      border-radius: 20px;
    }
  }
`;

const FlexItem = styled.div`
  margin: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .description {
    display: flex;
    flex-direction: column;
    & > h3 {
      font-size: 24px;
      color: #888;
    }
    & > p {
      font-size: 18px;
      color: #ccc;
    }
  }
`;

export default SiteFooter;
