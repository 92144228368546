import React from 'react';
import './App';
import useDetectDimension from 'rev.sdk.js/Hooks/useDetectDimension';
import ActivityIndicator from './Components/ActivityIndicator';
import LoginModal from 'rev.sdk.js/Components/LoginModal';
import ContactModal from './Components/ContactModal';
import ResetPasswordModal from './Components/ResetPasswordModal';
import * as User from 'rev.sdk.js/Actions/User';
import * as CtxUtil from './Utils/CtxUtil';

function Provider(props) {
  useDetectDimension();

  React.useEffect(() => {
    CtxUtil.onCtxRendered();
  }, []);

  React.useEffect(() => {
    async function tryAutoLogin() {
      const isAdmin =
        typeof window !== 'undefined' &&
        window.location.pathname.indexOf('admin') !== -1;

      try {
        await User.autoLogin({admin: isAdmin});
      } catch (ex) {
        console.log('autoLogin ex', ex);
      }
    }

    tryAutoLogin();
  }, []);

  console.log('AppCtx rendered');

  return (
    <>
      {props.children}

      <LoginModal
        canRegister={false}
        canFacebookLogin={false}
        canGoogleLogin={false}
        canLineLogin={false}
      />
      <ContactModal />
      <ResetPasswordModal />
      <ActivityIndicator />
    </>
  );
}

export {Provider};
