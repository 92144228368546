import React, {useCallback, useEffect, useState} from 'react';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Collapse,
  Empty,
  Form,
  Input,
  message,
  Select,
  Divider,
} from 'antd';
import {AntdAddressSetForm, Cart} from 'rev.sdk.js';
import * as AppActions from '../../AppActions';
import unimart from '../../../static/images/unimartc2c_logo.png';
import hilife from '../../../static/images/hilifec2c_logo.jpg';
import fami from '../../../static/images/famic2c_logo.png';
import qs from 'query-string';
import {useOutlet} from 'reconnect.js';
import CheckoutSummary from '../CheckoutSummary';
import {navigate} from 'gatsby';

const INPUT_TYPES = {
  text: 'text',
  select: 'select',
};

function GenericInput(props) {
  const {label, name, type, rules, config = {}} = props;
  if (type === INPUT_TYPES.text) {
    return (
      <Form.Item {...config} name={name} label={label} rules={rules}>
        <Input {...props} />
      </Form.Item>
    );
  } else {
    return (
      <Form.Item {...config} name={name} label={label} rules={rules}>
        <Select
          {...props}
          options={[
            {label: props.placeholder, value: '', disabled: true},
            ...props.options,
          ]}
        />
      </Form.Item>
    );
  }
}

export const PAYMENT_SUBTYPE = {
  default: 'default',
  credit: 'credit',
  cod: 'cod',
};

export const PAYMENT_SUBTYPE_DISPLAY = {
  [PAYMENT_SUBTYPE.default]: {
    label: '預設付款方式',
    value: PAYMENT_SUBTYPE.default,
  },
  [PAYMENT_SUBTYPE.credit]: {
    label: '信用卡付款',
    value: PAYMENT_SUBTYPE.credit,
  },
  [PAYMENT_SUBTYPE.cod]: {
    label: '貨到付款',
    value: PAYMENT_SUBTYPE.cod,
  },
};

export const LOGISTICS_TYPE = {
  cvs: 'CVS',
  home: 'HOME',
};

export const LOGISTICS_TYPE_DISPLAY = {
  [LOGISTICS_TYPE.cvs]: {
    label: '超商取貨',
    value: 'CVS',
  },
  [LOGISTICS_TYPE.home]: {
    label: '宅配到府',
    value: 'HOME',
  },
};

export const LOGISTICS_SUBTYPE = {
  famic2c: 'FAMIC2C',
  unimartc2c: 'UNIMARTC2C',
  hilifec2c: 'HILIFEC2C',
};

export const LOGISTICS_SUBTYPE_DISPLAY = {
  [LOGISTICS_SUBTYPE.famic2c]: {
    label: '全家便利超商',
    value: LOGISTICS_SUBTYPE.famic2c,
  },
  [LOGISTICS_SUBTYPE.unimartc2c]: {
    label: '統一便利超商',
    value: LOGISTICS_SUBTYPE.unimartc2c,
  },
  [LOGISTICS_SUBTYPE.hilifec2c]: {
    label: '萊爾富便利超商',
    value: LOGISTICS_SUBTYPE.hilifec2c,
  },
};

export const CVS_ICON = {
  [LOGISTICS_SUBTYPE.famic2c]: fami,
  [LOGISTICS_SUBTYPE.unimartc2c]: unimart,
  [LOGISTICS_SUBTYPE.hilifec2c]: hilife,
};

const getCartCvsInfo = (cart) => ({
  MerchantTradeNo: cart.MerchantTradeNo,
  CVSStoreID: cart.CVSStoreID,
  CVSStoreName: cart.CVSStoreName,
  CVSAddress: cart.CVSAddress,
  CVSTelephone: cart.CVSTelephone,
  CVSOutSide: cart.CVSOutSide,
});

const cvsInitialData = {
  MerchantTradeNo: '',
  CVSStoreID: '',
  CVSStoreName: '',
  CVSAddress: '',
  CVSTelephone: '',
  CVSOutSide: '',
};

const formatCheckoutFormSpecToAntdOptions = (
  cart,
  checkoutFormSpec,
  updateConfig,
) => {
  const paymentSubTypeOpts = checkoutFormSpec.paymentSubTypes.map(
    (ps) => PAYMENT_SUBTYPE_DISPLAY[ps],
  );
  const logisticsTypeOpts = checkoutFormSpec.logisticsTypes.map(
    (l) => LOGISTICS_TYPE_DISPLAY[l],
  );
  const logisticSubTypeOptsSet = {
    [LOGISTICS_TYPE.cvs]: checkoutFormSpec.logisticsSubTypes[
      LOGISTICS_TYPE.cvs
    ].map((ls) => LOGISTICS_SUBTYPE_DISPLAY[ls]),
    [LOGISTICS_TYPE.home]: checkoutFormSpec.logisticsSubTypes[
      LOGISTICS_TYPE.home
    ].map((ls) => LOGISTICS_SUBTYPE_DISPLAY[ls]),
  };
  const logisticSubTypeOpts = updateConfig
    ? logisticSubTypeOptsSet[updateConfig.logistics_type]
    : logisticSubTypeOptsSet[cart.logistics_type];
  return {
    paymentSubTypeOpts,
    logisticsTypeOpts,
    logisticSubTypeOptsSet,
    logisticSubTypeOpts,
  };
};

function CheckoutForm(props) {
  const {disabled = false, style = {}} = props;
  const [form] = Form.useForm();
  const [cart] = useOutlet('cart');
  const [isCartLoaded, setIsCartLoaded] = useState(false);
  const [isSameAsBuyer, setIsSameAsBuyer] = useState(false);
  const [paymentSubTypeOptions, setPaymentSubTypeOptions] = useState([]);
  const [logisticsTypeOptions, setLogisticsTypeOptions] = useState([]);
  const [logisticsSubTypeOptions, setLogisticsSubTypeOptions] = useState([]);
  const [logisticsSubTypeOptionsSet, setLogisticsSubTypeOptionsSet] = useState(
    null,
  );
  const [cvs, setCvs] = useState(cvsInitialData);

  const getCvs = useCallback((cart) => {
    if (!!window.location.search) {
      const parsed = qs.parse(window.location.search || '');
      return getCartCvsInfo(parsed);
    } else if (!!cart.CVSStoreID) {
      return getCartCvsInfo(cart);
    } else {
      return cvsInitialData;
    }
  }, []);

  useEffect(() => {
    const onLoaded = async () => {
      if (isCartLoaded || cart.items.length <= 0) {
        return;
      }
      const cvsInfo = getCvs(cart);
      form.setFieldsValue(cart);

      const {updateConfig, checkoutFormSpec} = AppActions.onCartLoaded(cart);

      if (updateConfig && !disabled) {
        setCvs((prev) => ({...prev, ...getCvs(updateConfig)}));
        await Cart.editConfig(updateConfig);
      }

      setCvs((prev) => ({...prev, ...cvsInfo}));

      const {
        paymentSubTypeOpts,
        logisticsTypeOpts,
        logisticSubTypeOptsSet,
        logisticSubTypeOpts,
      } = formatCheckoutFormSpecToAntdOptions(
        cart,
        checkoutFormSpec,
        updateConfig,
      );

      setPaymentSubTypeOptions(paymentSubTypeOpts);
      setLogisticsTypeOptions(logisticsTypeOpts);
      setLogisticsSubTypeOptions(logisticSubTypeOpts);
      setLogisticsSubTypeOptionsSet(logisticSubTypeOptsSet);
      setIsCartLoaded(true);
    };
    onLoaded();
  }, [cart, cvs, disabled, form, getCvs, isCartLoaded]);

  useEffect(() => {
    if (isSameAsBuyer) {
      form.setFieldsValue({
        receiver_name: form.getFieldValue('buyer_name'),
        receiver_phone: form.getFieldValue('buyer_phone'),
        receiver_email: form.getFieldValue('buyer_email'),
        receiver_tel: form.getFieldValue('buyer_tel'),
        receiver_tel_ext: form.getFieldValue('buyer_tel_ext'),
        receiver_zip: form.getFieldValue('buyer_zip'),
        receiver_city: form.getFieldValue('buyer_city'),
        receiver_district: form.getFieldValue('buyer_district'),
        receiver_address: form.getFieldValue('buyer_address'),
      });
    }
  }, [cart, form, isSameAsBuyer]);

  const getInputProps = useCallback(
    (field) => {
      return {
        payment_subtype: {
          label: '付款方式',
          name: field,
          value: form.getFieldValue(field),
          rules: [{required: true, message: '付款方式為必填值'}],
          disabled: disabled,
          onChange: (value) => {
            form.setFieldsValue({[field]: value});
          },
          type: INPUT_TYPES.select,
          placeholder: '請選擇付款方式',
          options: paymentSubTypeOptions,
        },
        logistics_type: {
          label: '物流方式',
          name: field,
          value: form.getFieldValue(field),
          rules: [{required: true, message: '物流方式為必填值'}],
          disabled: disabled,
          onChange: (value) => {
            form.setFieldsValue({
              [field]: value,
              logistics_subtype:
                logisticsSubTypeOptionsSet[value]?.[0]?.value || '',
            });
            setLogisticsSubTypeOptions(logisticsSubTypeOptionsSet[value]);
          },
          type: INPUT_TYPES.select,
          options: logisticsTypeOptions,
          placeholder: '請選擇物流方式',
        },
        logistics_subtype: {
          label: '選擇物流商',
          name: field,
          value: form.getFieldValue(field),
          rules: [{required: true, message: '物流商為必填值'}],
          disabled: disabled,
          onChange: (value) => {
            form.setFieldsValue({[field]: value});
            setCvs({
              MerchantTradeNo: '',
              CVSStoreID: '',
              CVSStoreName: '',
              CVSAddress: '',
              CVSTelephone: '',
              CVSOutSide: '',
            });
          },
          type: INPUT_TYPES.select,
          options: logisticsSubTypeOptions,
          placeholder: '請選擇物流商',
        },
        buyer_name: {
          label: '姓名',
          name: field,
          value: form.getFieldValue(field),
          rules: [{required: true, message: '姓名為必填值'}],
          disabled: disabled,
          onChange: (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請輸入姓名',
        },
        buyer_phone: {
          label: '行動電話',
          name: field,
          value: form.getFieldValue(field),
          rules: [
            {required: true, message: '行動電話為必填值'},
            {len: 10, message: '行動電話需為 10 碼'},
          ],
          maxLength: 10,
          disabled: disabled,
          onChange: (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請輸入行動電話',
        },
        buyer_email: {
          label: '電子郵件',
          name: field,
          value: form.getFieldValue(field),
          rules: [
            {required: true, message: '電子郵件為必填值'},
            {
              pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/,
              message: '需為合法電子郵件',
            },
          ],
          disabled: disabled,
          onChange: (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請輸入電子郵件',
        },
        buyer_tel: {
          label: '市話',
          name: field,
          value: form.getFieldValue(field),
          rules: [],
          disabled: disabled,
          onChange: (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請輸入市話',
        },
        buyer_tel_ext: {
          label: '市話分機',
          name: field,
          value: form.getFieldValue(field),
          rules: [],
          disabled: disabled,
          onChange: (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請輸入市話分機',
        },
        receiver_name: {
          label: '姓名',
          name: field,
          value: form.getFieldValue(field),
          rules: [{required: !isSameAsBuyer, message: '姓名為必填值'}],
          disabled: disabled || isSameAsBuyer,
          onChange: (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請輸入姓名',
        },
        receiver_phone: {
          label: '行動電話',
          name: field,
          value: form.getFieldValue(field),
          rules: [
            {required: !isSameAsBuyer, message: '行動電話為必填值'},
            {len: 10, message: '行動電話需為 10 碼'},
          ],
          maxLength: 10,
          disabled: disabled || isSameAsBuyer,
          onChange: (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請輸入行動電話',
        },
        receiver_email: {
          label: '電子郵件',
          name: field,
          value: form.getFieldValue(field),
          rules: [
            {required: !isSameAsBuyer, message: '電子郵件為必填值'},
            {
              pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/,
              message: '需為合法電子郵件',
            },
          ],
          disabled: disabled || isSameAsBuyer,
          onChange: (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請輸入電子郵件',
        },
        receiver_tel: {
          label: '市話',
          name: field,
          value: form.getFieldValue(field),
          rules: [],
          disabled: disabled || isSameAsBuyer,
          onChange: (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請輸入市話',
        },
        receiver_tel_ext: {
          label: '市話分機',
          name: field,
          value: form.getFieldValue(field),
          rules: [],
          disabled: disabled || isSameAsBuyer,
          onChange: (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請輸入市話分機',
        },
      }[field];
    },
    [
      disabled,
      form,
      isSameAsBuyer,
      logisticsSubTypeOptions,
      logisticsSubTypeOptionsSet,
      logisticsTypeOptions,
      paymentSubTypeOptions,
    ],
  );

  const onFinish = async (data) => {
    try {
      const withoutCvsSelect =
        data.logistics_type === LOGISTICS_TYPE.cvs && !cvs.CVSStoreID;
      if (withoutCvsSelect) {
        form.scrollToField('logistics_subtype', {
          behavior: (e) => {
            const {el, top} = e[0];
            el.scrollTo({
              left: 0,
              top: top - 100,
              behavior: 'smooth',
            });
          },
        });
        message.error('請選擇寄送超商');
        return;
      }

      AppActions.setLoading(true);
      const overridePayload = isSameAsBuyer
        ? {
            receiver_name: data.buyer_name,
            receiver_phone: data.buyer_phone,
            receiver_email: data.buyer_email,
            receiver_zip: data.buyer_zip,
            receiver_city: data.buyer_city,
            receiver_district: data.buyer_district,
            receiver_address: data.buyer_address,
            receiver_tel: data.buyer_tel,
            receiver_tel_ext: data.buyer_tel_ext,
          }
        : {};
      const payload = {
        ...data,
        ...cvs,
        ...overridePayload,
      };
      await Cart.editConfig(payload);
      await navigate('/checkout/review');
    } finally {
      AppActions.setLoading(false);
    }
  };

  const onError = (error) => {
    console.log('debug', error);
  };

  const onSelectCVS = async () => {
    try {
      //INFO : partial validate for below 3 fields
      await form.validateFields([
        'payment_subtype',
        'logistics_type',
        'logistics_subtype',
      ]);
      const values = form.getFieldsValue();
      await Cart.editConfig({
        ...cart,
        ...cvsInitialData,
        payment_subtype: values.payment_subtype,
        logistics_type: values.logistics_type,
        logistics_subtype: values.logistics_subtype,
      });
      await Cart.onSelectCVS();
    } catch (e) {
      console.log('debug', e);
    }
  };

  const commonItemConfig = {
    colon: false,
    labelAlign: 'left',
    style: {display: 'flex', flexDirection: 'column'},
  };

  return (
    <div style={style}>
      <Divider orientation="center">填寫表單</Divider>
      <Form
        onFinish={onFinish}
        onFinishFailed={onError}
        form={form}
        scrollToFirstError={{
          behavior: (e) => {
            const {el, top} = e[0];
            el.scrollTo({
              left: 0,
              top: top - 100,
              behavior: 'smooth',
            });
          },
        }}
        initialValues={cart}>
        <Collapse defaultActiveKey={[1, 2, 3, 4, 5]}>
          <Collapse.Panel header="結帳資訊" key={1}>
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('payment_subtype')}
            />
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('logistics_type')}
            />
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('logistics_subtype')}
            />
            {form.getFieldValue('logistics_type') === LOGISTICS_TYPE.cvs && (
              <Card
                style={{width: '100%'}}
                actions={[
                  <Button disabled={disabled} onClick={onSelectCVS}>
                    選擇超商
                  </Button>,
                ]}>
                {!!cvs.CVSStoreID ? (
                  <Card.Meta
                    avatar={
                      <Avatar
                        shape="square"
                        src={CVS_ICON[form.getFieldValue('logistics_subtype')]}
                      />
                    }
                    title={cvs.CVSStoreName}
                    description={cvs.CVSAddress}
                  />
                ) : (
                  <Empty />
                )}
              </Card>
            )}
          </Collapse.Panel>

          <Collapse.Panel header="買家聯絡資訊" key={2}>
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('buyer_name')}
            />
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('buyer_phone')}
            />
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('buyer_email')}
            />
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('buyer_tel')}
            />
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('buyer_tel_ext')}
            />
          </Collapse.Panel>

          <Collapse.Panel header="買家地址" key={3}>
            <AntdAddressSetForm
              itemConfig={commonItemConfig}
              isRequired={
                form.getFieldValue('logistics_type') === LOGISTICS_TYPE.home
              }
              isReadOnly={disabled}
              name="buyer"
              form={form}
            />
          </Collapse.Panel>

          <Collapse.Panel
            header="收件人聯絡資訊"
            key={4}
            extra={
              !disabled ? (
                <div style={{display: 'flex'}}>
                  <Checkbox
                    checked={isSameAsBuyer}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      setIsSameAsBuyer(e.target.checked);
                    }}
                  />
                  <div
                    style={{paddingLeft: 10}}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsSameAsBuyer((prev) => !prev);
                    }}>
                    同買家
                  </div>
                </div>
              ) : null
            }>
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('receiver_name')}
            />
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('receiver_phone')}
            />
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('receiver_email')}
            />
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('receiver_tel')}
            />
            <GenericInput
              config={commonItemConfig}
              {...getInputProps('receiver_tel_ext')}
            />
          </Collapse.Panel>

          <Collapse.Panel header="收件人地址" key={5}>
            <AntdAddressSetForm
              itemConfig={commonItemConfig}
              name="receiver"
              form={form}
              isReadOnly={disabled || isSameAsBuyer}
              isRequired={
                !isSameAsBuyer &&
                form.getFieldValue('logistics_type') === LOGISTICS_TYPE.home
              }
            />
          </Collapse.Panel>
        </Collapse>

        {!disabled && (
          <CheckoutSummary
            cart={cart}
            onClick={form.submit}
            isAllowNextStep={true}
          />
        )}
      </Form>
    </div>
  );
}

export default CheckoutForm;
