import {getOutlet} from 'reconnect.js';
import {navigate as nav} from 'gatsby';
import * as User from 'rev.sdk.js/Actions/User';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as PathUtil from 'rev.sdk.js/Utils/PathUtil';
import NavUrl from 'rev.sdk.js/Utils/NavUrl';
import * as Ant from 'antd';
import {fetchReservations} from '../Utils/ReservationUtil';
import {generateThumbnails} from '../Utils/ImageProcessingUtil';
import Config from '../../data.json';
import {
  LOGISTICS_SUBTYPE,
  LOGISTICS_TYPE,
  PAYMENT_SUBTYPE,
} from '../Components/CheckoutForm';
import * as Locale from '../Locale';

const req = ApiUtil.req;
const LoadingOutlet = getOutlet('loading');
const ApiHookOutlet = getOutlet('ApiUtil');
const UserOutlet = getOutlet('user');

function getPathName() {
  if (typeof window !== 'undefined' && window.location) {
    return window.location.pathname;
  }
  return '';
}

ApiHookOutlet.update({
  ...ApiHookOutlet.getValue(),
  onJson: (url, payload, jsonResp) => {
    // a sample hook, you can do whatever you want here
    return jsonResp;
  },
  onError: async (url, payload, resp) => {
    if (url.indexOf('token=') > -1 && resp.status === 410) {
      console.log('onError try autoLogin');
      const result = await User.autoLogin();
      if (result) {
        console.log('onError autoLogin success, fetch resource again', result);
        return req(url, payload, {ignoreOnErrorHook: true});
      } else {
        // other errors
        navigate('/');
        alert('權限過期或有另外使用者以同帳號登入系統，請重新登入');
      }
      console.log('onError autoLogin failure, throw original error', result);
      throw resp;
    }
  },
});

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function setLoading(loading) {
  setTimeout(() => {
    LoadingOutlet.update(loading);
  }, 0);
}

async function navigate(nextRoute, options = {loading: false}) {
  const {message = '', loading = false, ...rest} = options;
  if (nextRoute instanceof NavUrl) {
    nextRoute = nextRoute.toString();
  }

  const currRoute = PathUtil.normalizedRoute();
  nextRoute = PathUtil.normalizedRoute(nextRoute);
  if (currRoute !== nextRoute) {
    if (options?.loading) {
      LoadingOutlet.update({
        message: options.message,
        loading: options.loading,
      });
      if (typeof options.loading === 'number') {
        setTimeout(() => {
          LoadingOutlet.update(false);
        }, options.loading);
      }
    }
    await nav(nextRoute, rest);
  } else {
    console.log('path not changed, ignore...');
  }
  /*
  console.log('DBG', {nextRoute, options});
  const currRoute = '/' + window.location.href.split('/').slice(3).join('/');
  if (currRoute !== nextRoute) {
    if (options?.loading) {
      LoadingOutlet.update(true);
      if (typeof options.loading === 'number') {
        setTimeout(() => {
          LoadingOutlet.update(false);
        }, options.loading);
      }
    }
    nav(nextRoute);
  } else {
    console.log('path not changed, ignore...');
  }
  */
}

function renderCustomSection(props) {
  return null;
}

function renderCustomComponent(props) {
  return null;
}

async function clientJStorageFetch(collection, {cat, sort, search}) {
  const catQuery = cat ? {labels: {$regex: cat}} : {};
  const searchQuery = search ? {searchText: {$regex: search}} : {};
  const sortValue = sort ? [sort] : ['-created'];
  const extraQueries = {};
  let projection = null;

  if (collection === 'product') {
    // NOTICE: we perform the fetch in `ProductGrid` because we need paging!
    return [];
  } else if (collection === 'Article_Default') {
    delete catQuery.labels;
    if (!cat) {
      // catQuery.label = 'blog';
    } else {
      catQuery.label = {$regex: cat};
    }
    projection = {content: 0};
  }

  const resp = await JStorage.fetchDocuments(
    collection,
    {
      ...catQuery,
      ...searchQuery,
      ...extraQueries,
    },
    sortValue,
    null, // no paging for now, since our EC products shouldn't be too much
    projection, // if we're fetching Article, ignore the content
    {anonymous: true},
  );

  return resp;
}

const getDefaultCheckoutFormSpec = () => ({
  paymentSubTypes: [
    PAYMENT_SUBTYPE.default,
    PAYMENT_SUBTYPE.credit,
    PAYMENT_SUBTYPE.cod,
  ],
  logisticsTypes: [LOGISTICS_TYPE.cvs, LOGISTICS_TYPE.home],
  logisticsSubTypes: {
    [LOGISTICS_TYPE.cvs]: [
      LOGISTICS_SUBTYPE.famic2c,
      LOGISTICS_SUBTYPE.hilifec2c,
      LOGISTICS_SUBTYPE.unimartc2c,
    ],
    [LOGISTICS_TYPE.home]: [],
  },
});

function onCartLoaded(cart) {
  //TODO: depend on product logic for set init for cart value
  const checkoutFormSpec = getDefaultCheckoutFormSpec();
  const updateConfig = null;
  return {
    updateConfig,
    checkoutFormSpec,
  };
}

async function onLoginResult(err, result) {
  if (err) {
    console.warn('LOGIN fail w error', err);
  } else {
    console.log('LOGIN success w jwt token', result);
    /*
    sample result:
      aud: "newbalance" // client id
      env: "stg"
      exp: 1634873518
      grp: "admin" // "admin" (dashboard admin) or "" (user) or "management" (us, console account)
      iat: 1634866318
      iss: "auth-stg.revtel-api.com/v4"
      jti: "b1c8c55a-2a1a-42d4-9039-4270eefe9e2e"
      sub: "admin" // account 
      typ: "access"
    */
    if (result.grp === 'admin') {
      // do sth after admin login dashboard
    } else {
      // do sth after user login
      const queryKey = Config.jstoreVersion !== 'v1' ? 'owner' : 'id'; // can define directly if project's jstorage version has confirmed
      const profile = await JStorage.fetchOneDocument('user_profile', {
        [queryKey]: result.sub,
      });

      // if not in open season, only NBTEST & top_level account can login
      if (!Config.isOpenSeason) {
        if (profile.owner !== 'NBTEST' && profile.level !== 'top_level') {
          Ant.message.info('目前e-catalog為非開放時間！');
          return;
        }
      }

      Ant.message.info(`您現在以${profile.company_name}帳號登入`);

      UserOutlet.update({
        ...UserOutlet.getValue(),
        data: {...profile},
      });

      fetchReservations();
    }
  }
}

async function fetchCustomResources(resource, {sort, keyword, filter, paging}) {
  return null;
}

/**
 * **************************************************
 * (client) override Storage function for spacial behavior
 * **************************************************
 */

async function getUploadUrlFromFile(file, options = {}) {
  const fileKey = file.name;
  console.log('api fileKey------', fileKey);

  const fileType = file.type;
  const {acl = 'private', unique_key = false} = options;
  return await req(
    `${Config.storageHost}/storage/presigned/url?client_id=${
      Config.clientId
    }&token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        acl,
        unique_key,
        'Content-Type': fileType,
        key: `${fileKey}`,
      },
    },
  );
}

async function getImagesWithDealerV2(img_arr, size) {
  const sizeQuery = size ? `&w=${size}&h=${size}` : '';
  let imgWithDealer = img_arr.join(',');
  return await req(
    `${Config.nbImgHost}/images?token=${
      UserOutlet.getValue()?.token
    }&keys=${imgWithDealer}` + sizeQuery,
  );
}

function adminCanAccess(user, options = {}) {
  return true;
}

async function _uploadThumbnailImgs({s3Key, imgUrl}) {
  async function uploadThumbnailImg(_img, _s3Key) {
    const {url, fields} = await getUploadUrlFromFile({
      name: `${_s3Key}`,
      type: 'image/png',
    });

    const formData = new FormData();
    for (const field in fields) {
      formData.append(field, fields[field]);
    }

    const blob = await (await fetch(_img)).blob();
    formData.append('file', blob);

    await fetch(url, {
      method: 'POST',
      body: formData,
    });
  }
  const {img, imgSmall} = await generateThumbnails(imgUrl);

  await uploadThumbnailImg(img, s3Key);
  await uploadThumbnailImg(imgSmall, s3Key + '.small');
  Ant.message.success('All dealer images uploaded!');
}

async function onAdminFormSubmit({
  path,
  collection,
  instance,
  extValues,
  formData,
  primaryKey,
}) {
  if (path === '/admin/products') {
    setLoading(true);
    try {
      const nextImages = [];
      for (const image of formData.images || []) {
        const [name, blob] = image.split('___');
        if (blob) {
          const s3Key = `${instance.model}/${name}`;
          await _uploadThumbnailImgs({
            imgUrl: blob,
            s3Key,
          });
          nextImages.push(s3Key);
        } else {
          nextImages.push(image);
        }
      }

      formData.images = nextImages;

      if (!instance) {
        await JStorage.createDocument(collection, {
          ...formData,
        });
        navigate(path);
      } else {
        await JStorage.updateDocument(
          collection,
          {[primaryKey]: instance[primaryKey]},
          {...formData},
        );
      }

      Ant.message.success('成功!');
    } catch (ex) {
      Ant.message.error('API failure');
    }
    setLoading(false);
    return true;
  }

  return false;
}

async function onAfterAdminFormSubmit(
  {path, collection, instance, extValues, formData, primaryKey},
  updatedInstance,
) {
  if (
    path.indexOf('/admin/landing') > -1 ||
    path.indexOf('/admin/product_category') > -1 ||
    path.indexOf('/admin/article_category') > -1 ||
    path.indexOf('/admin/video_category') > -1 ||
    path.indexOf('/admin/config') > -1 ||
    path.indexOf('/admin/about') > -1
  ) {
    await JStorage.cacheDocuments('site', {}, null, null, null, undefined, {
      key: 'rev-site-cache.json',
    });
  }

  return null;
}

function L(key, defaultValue) {
  console.log('DBGGG', key, defaultValue);
  return Locale.getLabel(key, defaultValue);
}

function F(obj, field) {
  return Locale.getField(obj, field);
}

export {
  delay,
  setLoading,
  navigate,
  renderCustomSection,
  renderCustomComponent,
  clientJStorageFetch,
  fetchCustomResources,
  onCartLoaded,
  getUploadUrlFromFile,
  getImagesWithDealerV2,
  onLoginResult,
  adminCanAccess,
  onAdminFormSubmit,
  onAfterAdminFormSubmit,
  L,
  F,
};
