// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-generators-admin-resource-index-js": () => import("./../../../src/Generators/AdminResource/index.js" /* webpackChunkName: "component---src-generators-admin-resource-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-admin-index-js": () => import("./../../../src/Templates/Admin/index.js" /* webpackChunkName: "component---src-templates-admin-index-js" */),
  "component---src-templates-article-detail-index-js": () => import("./../../../src/Templates/ArticleDetail/index.js" /* webpackChunkName: "component---src-templates-article-detail-index-js" */),
  "component---src-templates-blog-detail-index-js": () => import("./../../../src/Templates/BlogDetail/index.js" /* webpackChunkName: "component---src-templates-blog-detail-index-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/Templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/Templates/News/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-product-detail-index-js": () => import("./../../../src/Templates/ProductDetail/index.js" /* webpackChunkName: "component---src-templates-product-detail-index-js" */),
  "component---src-templates-product-list-index-js": () => import("./../../../src/Templates/ProductList/index.js" /* webpackChunkName: "component---src-templates-product-list-index-js" */),
  "component---src-templates-profile-index-js": () => import("./../../../src/Templates/Profile/index.js" /* webpackChunkName: "component---src-templates-profile-index-js" */),
  "component---src-templates-reservation-list-index-js": () => import("./../../../src/Templates/ReservationList/index.js" /* webpackChunkName: "component---src-templates-reservation-list-index-js" */),
  "component---src-templates-video-list-index-js": () => import("./../../../src/Templates/VideoList/index.js" /* webpackChunkName: "component---src-templates-video-list-index-js" */)
}

