import styled from 'styled-components';
import {Button, Divider, List, Row} from 'antd';
import React, {Fragment} from 'react';

const StyledCheckoutSummary = styled.div``;

const CheckoutSummary = (props) => {
  const {cart, onClick, isAllowNextStep, buttonLabel = '下一步'} = props;
  return (
    <StyledCheckoutSummary>
      <Divider orientation="center">金額資訊</Divider>
      <List
        header={
          <Row justify="space-between">
            <h4>小計</h4>
            <h4>${cart.subtotal}</h4>
          </Row>
        }
        size="small"
        bordered
        dataSource={[
          ...cart.extra_items.map((i) => ({...i, type: 'extra'})),
          ...cart.discount_items.map((i) => ({...i, type: 'discount'})),
        ]}
        renderItem={(i) => (
          <Fragment>
            {i.type === 'extra' && (
              <Fragment>
                <List.Item>
                  <h4>{i.name}</h4>
                  <h4>${i.amount}</h4>
                </List.Item>
              </Fragment>
            )}

            {i.type === 'discount' && (
              <Fragment>
                <List.Item>
                  <h4 style={{color: 'red'}}>{i.name}</h4>
                  <h4 style={{color: 'red'}}>-${i.amount}</h4>
                </List.Item>
              </Fragment>
            )}
          </Fragment>
        )}
        footer={
          <Row justify="space-between">
            <h2>總金額</h2>
            <h2>${cart.total}</h2>
          </Row>
        }
      />
      <Button
        size="large"
        type="primary"
        disabled={!isAllowNextStep}
        onClick={onClick}
        style={{marginTop: 10, width: '100%'}}>
        {buttonLabel}
      </Button>
    </StyledCheckoutSummary>
  );
};

export default CheckoutSummary;
