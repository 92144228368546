import {getNewOutlet} from 'reconnect.js';
import {buildCatDisplayMap} from '../Utils/buildCatDisplayMap';

const defaultCats = [
  {
    name: 'all',
    display: '所有分類',
    items: [],
  },
];

// configurations for "Article_Default" collection
getNewOutlet('user', null, {autoDelete: false});
getNewOutlet('loading', false, {autoDelete: false});
getNewOutlet('login-modal', false, {autoDelete: false});
getNewOutlet('contact-modal', false, {autoDelete: false});
getNewOutlet('reset-password-modal', false, {autoDelete: false});

// product collection
getNewOutlet('categories', defaultCats, {autoDelete: false});
getNewOutlet('categoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});

// video collection
getNewOutlet('videoCategories', defaultCats, {autoDelete: false});
getNewOutlet('videoCategoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});

getNewOutlet(
  'sortOptions',
  [
    {name: '-created', display: '上架時間(由新到舊)'},
    {name: 'price', display: '價格(由低到高)'},
    {name: 'created', display: '上架時間(由舊到新)'},
    {name: '-price', display: '價格(由高到低)'},
  ],
  {autoDelete: false},
);

// Article_Default collection
getNewOutlet('articleCategories', defaultCats, {autoDelete: false});
getNewOutlet('articleCategoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});
getNewOutlet(
  'articleSortOptions',
  [
    {name: '-created', display: '發布時間(由新到舊)'},
    {name: 'created', display: '發布時間(由舊到新)'},
  ],
  {autoDelete: false},
);

getNewOutlet(
  'cart',
  {
    items: [],
    extra_items: [],
    discount_items: [],
  },
  {autoDelete: false},
);
getNewOutlet('dimension', {rwd: 'mobile'}, {autoDelete: false});
getNewOutlet('actions', null, {autoDelete: false});
getNewOutlet('ApiUtil', {}, {autoDelete: false});
getNewOutlet('reservation', [], {autoDelete: false});
getNewOutlet('dealers', [], {autoDelete: false});
getNewOutlet('locale', {lang: 'tw'});
